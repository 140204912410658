<template>
  <div class="NotFound">
    Not Found
  </div>
</template>

<script>

export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>
.notFound {
}
</style>
